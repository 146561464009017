/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TabList.scss';
import TabItem from './TabItem/TabItem';
import * as Utility from '../../utils/utility';

const activeClass = 'active';

class TabList extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      slices: PropTypes.array,
    })).isRequired,
  };

  componentDidMount() {
    this.changeTab(window.location.hash?.slice(1));
    this.onHashChange = () => this.changeTabUsingHash();
    window.addEventListener('hashchange', this.onHashChange);
  }

  componentDidUpdate() {
    this.changeTab(window.location.hash?.slice(1));
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
  }

  handleClick(e) {
    if (!Utility.hasClass(e.target, 'active')) {
      this.changeTab(e.target.dataset.tab);
      window.location.hash = e.target.dataset.tab;
    }
  }

  changeTab(activeTabValue) {
    const { tabList } = this;
    const activeElements = tabList.getElementsByClassName(activeClass);
    const activeElementsArray = Array.from(activeElements);

    let newActiveElements = tabList.querySelectorAll(`[data-tab='${activeTabValue}']`);

    if (!newActiveElements?.length) {
      newActiveElements = tabList.querySelectorAll(`[data-tab='${tabList.querySelector('.tabButton')?.dataset?.tab}']`);
    }

    if (!newActiveElements?.length) {
      return;
    }

    activeElementsArray.forEach(element => {
      Utility.removeClass(element, activeClass);
    });

    [].forEach.call(newActiveElements, element => {
      Utility.addClass(element, activeClass);
    });
  }

  changeTabUsingHash() {
    this.changeTab(window.location.hash.slice(1));
  }

  render() {
    const { items } = this.props;

    return (
      <div className={`tabWrap ${s.tabList}`} ref={el => { this.tabList = el; }}>
        <div className={s.tabNav}>
          <div className={s.tabNavInner}>
            {items.map((item, index) => (
              <button
                type="button"
                onClick={(e => this.handleClick(e))}
                key={index}
                data-tab={index}
                className={`tabButton ${s.tabButton} ${index === 0 ? activeClass : ''}`}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        {items.map((item, index) => (
          <TabItem name={item.name} slices={item.slices} key={index} index={index} />
        ))}
      </div>
    );
  }
}

export default withStyles(s)(TabList);
