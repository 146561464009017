import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import TrendIcon from '../TrendIcon';
import Link from '../Link';
import { formatNumber } from '../../utils/utility';
import s from './RankingListTable.scss';

function getBadge(micrositeLogoUrl) {
  if (micrositeLogoUrl && micrositeLogoUrl !== 'string') {
    return `url(${micrositeLogoUrl})`;
  }

  return 'url(/badge_placeholder.gif)';
}

function wrapLink(target, renderContent) {
  if (!target) {
    return renderContent();
  }

  return (
    <Link to={target} className={s.communityLink}>
      {renderContent()}
    </Link>
  );
}

class RankingListTable extends React.Component {
  static propTypes = {
    displayShowMore: PropTypes.bool,
    onShowMore: PropTypes.func,
    list: PropTypes.array,
    type: PropTypes.oneOf(['communities', 'teams']),
    className: PropTypes.string,
    hideMinutes: PropTypes.bool,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    displayShowMore: false,
    list: [],
    type: 'communities',
    className: '',
    hideMinutes: false,
  };

  render() {
    const { list, displayShowMore, onShowMore, type, className, hideMinutes } = this.props;
    const { i18n } = this.context;

    return (
      <div className={`${s.tableContainer} ${className}`}>
        <table className={s.table}>
          <thead>
            <tr>
              <td>{i18n.t('ranking.position')}</td>
              <td>{i18n.t(type === 'communities' ? 'ranking.community' : 'ranking.team')}</td>
              {!hideMinutes && (<td>{i18n.t('ranking.minutes')}</td>)}
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <tr key={item.id}>
                <td className={s.cellPosition}>
                  <div className={s.positionWrap}>
                    {type === 'communities' && (
                      <>
                        <TrendIcon className={s.trendIcon} now={item.currentRank} previous={item.previousRank} />
                        {' '}
&nbsp;
                      </>
                    )}
                    <span className={s.rank}>{item.currentRank || '–'}</span>
                  </div>
                </td>
                <td className={s.cellItem}>
                  {wrapLink(item.url, () => (
                    <>
                      {type === 'communities' && (
                        <div
                          className={s.communityBadge}
                          style={{ backgroundImage: getBadge(item.micrositeLogoUrl) }}
                        />
                      )}
                      <span className={s.name}>{item.name || item.participantName}</span>
                    </>
                  ))}
                </td>
                {!hideMinutes && (
                  <td className={s.cellMinutes}>
                    {wrapLink(item.url, () => (
                      <div className={s.minutesWrap}>
                        <span>{formatNumber(item.currentTotalMinutes)}</span>
&nbsp;
                        <span className={s.minutesDescription}>{i18n.t('ranking.minutes')}</span>
                      </div>
                    ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {displayShowMore && (
          <div className={s.moreLinkWrap}>
            <button className={s.moreLink} onClick={onShowMore} type="button">{i18n.t('ranking.showMore')}</button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(RankingListTable);
